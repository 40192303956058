import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './styles/globals.css';
import reportWebVitals from './reportWebVitals';
import 'primeicons/primeicons.css';
import "./components/styles.css";


// Prevent zooming with keyboard shortcuts
document.addEventListener('keydown', function(event) {
  if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-' || event.key === '0')) {
    event.preventDefault();
  }
});

// Prevent zooming with mouse scroll
document.addEventListener('wheel', function(event) {
  if (event.ctrlKey || event.metaKey) {
    event.preventDefault();
  }
}, { passive: false });

// Prevent pinch-to-zoom on touchpads
document.addEventListener('gesturestart', function(event) {
  event.preventDefault();
});
document.addEventListener('gesturechange', function(event) {
  event.preventDefault();
});
document.addEventListener('gestureend', function(event) {
  event.preventDefault();
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
