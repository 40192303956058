import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import UserReviewsMarquee from './UserReviewsMarquee';
import { FaStar, FaBed, FaShower, FaSnowflake, FaWifi, FaTv, FaFire } from 'react-icons/fa';

const CombinedCard = () => {
  const userList = [
    { image: 'https://randomuser.me/api/portraits/men/1.jpg', name: 'John Doe', experience: 'Breathtaking natural beauty', rating: '4.5/5' },
    { image: 'https://randomuser.me/api/portraits/women/2.jpg', name: 'Jane Smith', experience: 'Exciting atmosphere to enjoy', rating: '4.7/5' },
    { image: 'https://randomuser.me/api/portraits/men/3.jpg', name: 'Mike Johnson', experience: 'Serene and peaceful setting', rating: '4.6/5' },
    { image: 'https://randomuser.me/api/portraits/women/4.jpg', name: 'Emily Davis', experience: 'Amazing spot to visit', rating: '4.8/5' },
    { image: 'https://randomuser.me/api/portraits/men/5.jpg', name: 'Chris Brown', experience: 'Great place to explore', rating: '4.5/5' },
    {
      image: 'https://randomuser.me/api/portraits/men/6.jpg',
      name: 'John Smith',
      experience: 'Family coast trip',
      rating: '4.7/5'
    },
    {
      image: 'https://randomuser.me/api/portraits/women/5.jpg',
      name: 'Sara Wilson',
      experience: 'Lovely stay in newport',
      rating: '4.9/5'
    },
    {
      image: 'https://randomuser.me/api/portraits/men/7.jpg',
      name: 'James Taylor',
      experience: 'Beautiful Home',
      rating: '4.6/5'
    },
    {
      image: 'https://randomuser.me/api/portraits/women/6.jpg',
      name: 'Anna Roberts',
      experience: 'Great Spot',
      rating: '4.4/5'
    },
    {
      image: 'https://randomuser.me/api/portraits/men/8.jpg',
      name: 'David Lee',
      experience: 'We Love this House !',
      rating: '4.8/5'
    }
  ];

  const { ref: reasonsRef, inView: reasonsInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div className="mt-6 border border-gray-300 rounded-2xl p-6 bg-white shadow-lg font-poppins">
      {/* Text and Star Section */}
      <div className="mb-4">
        <h2 className="text-lg font-bold text-gray-800 mb-2">
        Ocean Front Home in the Heart of Newport 
      </h2>
        <p className="text-sm text-gray-800 mb-2">
        Breathtaking beauty awaits you! Enjoy all of the tranquility and rejuvenation with family and friends by experiencing what the blessed Oregon coast evokes in people. The only things separating this beloved ocean front home and the Pacific Ocean !         </p>
        <div className="bg-black text-white p-2 rounded-lg shadow-md flex mt-1 items-center">
          <FaStar className="text-yellow-400 text-xs mr-1" />
          <span className="font-bold text-xs">5 Star</span>
        </div>
      </div>

      <h2 className="text-lg font-bold text-gray-800">User Reviews</h2>
      <div className="p-4 mb-4">
      <UserReviewsMarquee userList={userList} />
    </div>
      <hr className="my-2 mt-4 border-gray-300 mb-4" />

      {/* Icons and Text Section */}
      <h2 className="text-lg font-bold text-gray-800 mb-4">Reasons to Book</h2>
      <div ref={reasonsRef} className="grid grid-cols-3 gap-4 mb-4">
        {[
          { src: 'https://img.icons8.com/emoji/50/shield-emoji.png', alt: 'Safety Measures', text: 'Safety Measures', delay: 0.4 },
          { src: 'https://img.icons8.com/3d-fluency/94/verified-account.png', alt: 'Includes All Essentials', text: 'Full Featured', delay: 0.6 },
          { src: 'https://img.icons8.com/3d-fluency/94/christmas-star.png', alt: '5-Star Rated', text: '5 Star Rated', delay: 0.8 }
        ].map((item, index) => (
          <motion.div
            key={index}
            className="flex flex-col items-center border border-gray-300 rounded-2xl p-4 bg-white shadow-md"
            initial={{ opacity: 0, y: 50 }}
            animate={reasonsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.6, delay: item.delay }}
          >
            <img
              src={item.src}
              alt={item.alt}
              width="30"
              height="30"
              className="mb-2"
            />
            <p className="text-gray-600 text-xs text-center">{item.text}</p>
          </motion.div>
        ))}
      </div>

      <hr className="mt-8 my-2 border-gray-300" />

      {/* Additional Details Section */}
      <div className="mb-4">
        <h2 className="text-lg font-bold text-gray-800 mb-2">Overview</h2>
        <div className="flex flex-col space-y-2">
          <div className="flex items-center text-sm text-gray-600">
            <FaBed className="mr-2 text-black" /> 1 bedroom
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <FaShower className="mr-2 text-black" /> 1 bathroom
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <FaSnowflake className="mr-2 text-black" /> House
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <FaWifi className="mr-2 text-black" /> High speed internet
          </div>
        </div>
      </div>

      <hr className="my-2 border-gray-300" />

      {/* Amenities Section */}
      <div className="mb-4">
        <h2 className="text-lg font-bold text-gray-800 mb-2">Amenities</h2>
        <div className="flex flex-col space-y-2 text-sm text-gray-600">
          <div className="flex items-center">
            <FaTv className="mr-2 text-black" /> TV
          </div>
          <div className="flex items-center">
            <FaFire className="mr-2 text-black" /> Fireplace
          </div>
          <div className="flex items-center">
            <FaShower className="mr-2 text-black" /> Dishwasher
          </div>
          <div className="flex items-center">
            <FaWifi className="mr-2 text-black" /> Air conditioning
          </div>
        </div>
      </div>

      <hr className="my-2 border-gray-300" />

      {/* Safety and Hygiene Section */}
      <div className="mb-4">
        <h2 className="text-lg font-bold text-gray-800 mb-2">Safety and Hygiene</h2>
        <ul className="list-disc pl-4 text-sm text-gray-600">
          <li>Sanitized rooms</li>
          <li>Social distancing measures</li>
          <li>Contactless check-in/check-out</li>
        </ul>
      </div>

      <hr className="my-2 border-gray-300 mt-6" />

      {/* Nearby Attractions Section */}
      <div className="mb-4">
        <h2 className="text-lg font-bold text-gray-800 mb-2">Nearby Attractions</h2>
        <div className="flex flex-col space-y-2 text-sm text-gray-600">
          <ul className="list-disc pl-4">
            <li>Scenic Hiking Trails - 2 miles</li>
            <li>Local Dining Spots - 5 miles</li>
            <li>National Park - 13 miles</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CombinedCard;
