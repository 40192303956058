import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const AnimatedCard = () => {
  const controls = useAnimation();
  const navigate = useNavigate();

  const handleScroll = () => {
    const element = document.getElementById('animated-card');
    const rect = element.getBoundingClientRect();
    const isInView = rect.top <= window.innerHeight && rect.bottom >= 0;

    if (isInView) {
      controls.start({ opacity: 1, scale: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, scale: 0.95, y: 50 });
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [controls]);

  // Ripple Effect Handler
  const handleRipple = (e) => {
    const button = e.currentTarget;
    const ripple = document.createElement('span');
    const rect = button.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = e.clientX - rect.left - size / 2;
    const y = e.clientY - rect.top - size / 2;

    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
    ripple.classList.add('ripple');
    button.appendChild(ripple);

    // Remove ripple element after animation
    ripple.addEventListener('animationend', () => {
      ripple.remove();
    });
  };

  // Navigate to mainpage-2 on button click
  const handleViewDealClick = () => {
    navigate('/main');
  };

  return (
    <motion.div
      id="animated-card"
      className="w-full max-w-md mx-auto bg-white rounded-2xl shadow-lg overflow-hidden mb-20 mt-8"
      initial={{ opacity: 0, scale: 0.95, y: 50 }}
      animate={controls}
      transition={{ duration: 0.4, ease: "easeOut" }}
    >
      <img
        className="w-full h-48 object-cover"
        src="https://media.vrbo.com/lodging/20000000/19080000/19071400/19071307/189ed6f0.jpg?impolicy=resizecrop&rw=1200&ra=fit"
        alt="property"
      />
      <div className="p-4">
        <h3 className="text-lg font-semibold">Get Similar Deals</h3>
        <p className="text-sm mt-2">
          Oceanfront Newport Whale Watch, Single Story Home, W/Hot Tub !
        </p>
        <div className="mt-4 flex items-center">
          <span className="text-yellow-400 text-lg">&#9733;&#9733;&#9733;&#9733;&#9734;</span>
          <span className="ml-2 font-bold">4.5/5</span>
          <button
            onClick={(e) => {
              handleRipple(e);
              handleViewDealClick(); // Navigate on click
            }}
            className="ml-auto bg-black text-white px-4 py-2 rounded-2xl font-bold relative overflow-hidden"
          >
            <span className="relative">View Deal</span>
            <span className="absolute inset-0 rounded-full bg-white opacity-0 transition-opacity duration-300"></span>
          </button>
        </div>
      </div>

      {/* Ripple effect styles */}
      <style jsx>{`
        .ripple {
          position: absolute;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.5);
          pointer-events: none;
          animation: ripple-animation 0.6s linear;
        }
        @keyframes ripple-animation {
          from {
            transform: scale(0);
            opacity: 1;
          }
          to {
            transform: scale(2);
            opacity: 0;
          }
        }
      `}</style>
    </motion.div>
  );
};

export default AnimatedCard;
