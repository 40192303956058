import React from 'react';
import { FaBus, FaWalking, FaPlane, FaCar, FaHotel } from 'react-icons/fa';

const MapCard = () => {
  return (
    <div className="mt-6 border border-gray-300 rounded-2xl p-6 bg-white shadow-lg">
      <div className="relative mb-6">
        {/* Map Embed with improved styling */}
        <div className="overflow-hidden rounded-2xl shadow-lg">
          <iframe
            className="w-full h-64 transition-transform transform hover:scale-105 duration-300 ease-in-out rounded-lg"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17572.979685546707!2d-124.05706855924872!3d44.6371430079952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54c1d5db4c7c988d%3A0xd41f72f161fcfb72!2sNewport%2C%20OR%2C%20USA!5e0!3m2!1sen!2sin!4v1726937147735!5m2!1sen!2sin"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            title="map"
          ></iframe>
        </div>

        {/* Overlay title on top of map */}
        <div className="absolute top-0 left-0 bg-black bg-opacity-50 text-white p-2 rounded-2xl">
          <p className="text-sm font-semibold">Location: Newport Oregon, USA</p>
        </div>
      </div>

      {/* Location Info */}
      <p className="text-lg font-bold text-gray-800">Location Details</p>
      <p className="mt-2 text-gray-600 text-sm">Newport is a city on Oregon’s central coast. The Oregon Coast Aquarium, on Yaquina Bay, features local marine life, with underwater walkways and a seabird aviary. </p>

      {/* Key Features with Vertically Aligned Icons */}
      <div className="flex flex-col items-start mt-4 space-y-4">
        <div className="flex items-center space-x-2">
          <FaBus className="text-black text-xl" />
          <p className="text-gray-600 text-sm">5 Miles from Bus Stand</p>
        </div>
        <div className="flex items-center space-x-2">
          <FaWalking className="text-black text-xl" />
          <p className="text-gray-600 text-sm">10min walk to Local Market</p>
        </div>
        <div className="flex items-center space-x-2">
          <FaPlane className="text-black text-xl" />
          <p className="text-gray-600 text-sm">2 Miles from Airport</p>
        </div>
        <div className="flex items-center space-x-2">
          <FaCar className="text-black text-xl" />
          <p className="text-gray-600 text-sm">Easy Access to Taxi Services</p>
        </div>
        <div className="flex items-center space-x-2">
          <FaHotel className="text-black text-xl" />
          <p className="text-gray-600 text-sm">Nearby Hotels and Restaurants</p>
        </div>
      </div>

      {/* Additional Information */}
      <div className="mt-6 border-t border-gray-300 pt-4">
        <p className="text-gray-800 font-bold">Nearby Attractions</p>
        <ul className="list-disc list-inside text-gray-600 text-sm mt-2">
          <li>Arched Yaquina Bay Bridge</li>
          <li>Yaquina Bay State Park</li>
          <li>Head Lighthouse</li>
        </ul>
      </div>
    </div>
  );
};

export default MapCard;
