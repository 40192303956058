import React from 'react';

const Footer = () => {
  return (
    <>
      <style>
        {`
          .glassmorphism-footer {
            background: rgba(255, 255, 255, 0.2); /* Transparent white */
            backdrop-filter: blur(10px); /* Blur effect */
            -webkit-backdrop-filter: blur(10px); /* Safari support */
            border-radius: 10px; /* Rounded corners */
            border: 1px solid rgba(255, 255, 255, 0.3); /* Light border */
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
          }
        `}
      </style>

      <footer className="glassmorphism-footer text-gray-600 text-center py-6">
        <p className="text-sm">&copy; 2024. All rights reserved.</p>
      </footer>
    </>
  );
};

export default Footer;
