import React, { useState, useEffect } from 'react';
import { MdContactSupport } from 'react-icons/md';
import { motion } from 'framer-motion';

const FloatingButton = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    window.location.href = 'mailto:anvteamofficial@gmail.com';
  };

  useEffect(() => {
    setShowTooltip(true);
    const timer = setTimeout(() => {
      setShowTooltip(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative">
      <button
        className="fixed z-50 bottom-6 mb-8 right-6 bg-black text-white p-4 rounded-full shadow-lg hover:bg-blue-600 transition duration-300"
        onClick={handleClick}
      >
        <MdContactSupport size={24} />
      </button>

      {showTooltip && (
        <motion.div
          className="absolute bottom-8 mb-8 mr-6 right-16 bg-gray-800 text-white text-s rounded-lg p-2"
          initial={{ opacity: 0, x: 10 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 10 }}
          transition={{ duration: 1.5 }}
        >
     
        </motion.div>
      )}
    </div>
  );
};

export default FloatingButton;
