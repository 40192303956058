import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const CardSection = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/main');
  };

  const handleNavigateTwo = () => {
    navigate('/mainpage-2');
  };

  // Sample locations
  const locations = [
    'Mountain View Lodge',
    'Seaside Retreat',
    'Urban Loft',
    'Countryside Haven',
    'Beachfront Villa',
  ];

  // Function to get a random location
  const getRandomLocation = () => {
    const randomIndex = Math.floor(Math.random() * locations.length);
    return locations[randomIndex];
  };

  return (
    <>
      <style>
        {`
          .moving-border {
            position: relative;
            display: inline-block;
            padding: 2px;
            border-radius: 50px;
            background: linear-gradient(90deg, black, white, black, white);
            background-size: 300%;
            animation: border-animation 3s linear infinite;
          }

          @keyframes border-animation {
            0% {
              background-position: 0%;
            }
            100% {
              background-position: 100%;
            }
          }

          .image-hover {
            cursor: pointer;
            transition: transform 0.3s ease;
          }

          .image-hover:hover {
            transform: scale(1.05);
          }
        `}
      </style>

      <div className="flex flex-col space-y-8 mt-24">
        {/* Card 1 */}
        <motion.div
          className="bg-white bg-opacity-30 backdrop-blur-lg border border-white rounded-2xl overflow-hidden shadow-lg"
          initial={{ opacity: 0, x: 100 }} // Start from the right
          animate={{ opacity: 1, x: 0 }} // Move to its original position
          transition={{ duration: 0.8 }}
        >
          <img 
            src="https://media.vrbo.com/lodging/20000000/19080000/19071400/19071307/9f75d629.jpg?impolicy=resizecrop&rw=1200&ra=fit"
            alt="Luxury Cabin"
            className="w-full h-48 object-cover rounded-t-2xl image-hover"
            onClick={handleNavigate}
          />
          <div className="p-4">
            <h2 className="text-l font-semibold mb-2">Ocean Front Home in the Heart of Newport </h2>
            <p className="text-gray-500 text-sm mb-4">Newport, Oregon, United States of America</p>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="text-yellow-400 mr-2">★★★★☆</span>
                <span className="text-gray-600">4.5/5</span>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Card 2 */}
        <motion.div
          className="bg-white bg-opacity-30 backdrop-blur-lg border border-white rounded-2xl overflow-hidden shadow-lg"
          initial={{ opacity: 0, x: -100 }} // Start from the left
          animate={{ opacity: 1, x: 0 }} // Move to its original position
          transition={{ duration: 1.3 }}
        >
          <img 
            src="https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/0822140d.jpg?impolicy=resizecrop&rw=1200&ra=fit"
            alt="Beautiful Place"
            className="w-full h-48 object-cover rounded-t-2xl image-hover"
            onClick={handleNavigateTwo}
          />
          <div className="p-4">
            <h2 className="text-l font-semibold mb-2">Oceanfront Newport Whale Watch, Single Story Home, W/Hot Tub !</h2>
            <p className="text-gray-500 text-sm mb-4">Newport, Oregon, United States of America</p>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="text-yellow-400 mr-2">★★★★★</span>
                <span className="text-gray-600">5/5</span>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default CardSection;
