import React from 'react';
import { FaStar } from 'react-icons/fa';

// UserCard Component
const UserCard = ({ user }) => {
  return (
    <div className="flex flex-col items-center p-4 bg-white rounded-2xl border border-gray-300 w-48 h-auto overflow-hidden">
      <img
        src={user.image}
        alt={user.name}
        className="w-12 h-12 rounded-full border-2 border-gray-300 mb-2"
      />
      <div className="w-full text-center">
        <h4 className="text-lg font-semibold mb-1 overflow-hidden text-ellipsis whitespace-nowrap">{user.name}</h4>
        <p className="text-xs text-gray-600 mb-2 overflow-hidden text-ellipsis whitespace-nowrap">{user.experience}</p>
      </div>
      <div className="flex items-center">
        <FaStar className="text-yellow-400 text-sm" />
        <span className="ml-1 text-xs font-bold">{user.rating}</span>
      </div>
    </div>
  );
};

export default UserCard;
