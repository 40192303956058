import React, { useEffect, useState } from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css'; // Import the CSS for carousel
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const ImageSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const images = [
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/5e72a3ab.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/a660755f.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/3490bba3.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/09a5389b.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/a80230e7.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/eafb3dee.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/0822140d.jpg?impolicy=resizecrop&rw=1200&ra=fit',
  ];

  useEffect(() => {
    const autoScroll = () => {
      setCurrentSlide((prev) => (prev + 1) % images.length);
    };

    const intervalId = setInterval(autoScroll, 3000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <div className="relative mt-6 overflow-hidden">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={images.length}
        isIntrinsicHeight={true}
        visibleSlides={1}
        currentSlide={currentSlide}
        infinite={true}
      >
        <Slider className="flex w-full overflow-x-hidden">
          {images.map((image, index) => (
            <Slide key={index} index={index}>
              <div className="relative w-full">
                <img
                  className="w-full h-64 object-cover rounded-2xl"
                  src={image}
                  alt={`slider${index + 1}`}
                />
              </div>
            </Slide>
          ))}
        </Slider>
        {/* Navigation Buttons */}
        <ButtonBack className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white p-2 rounded-full">
          <FaChevronLeft />
        </ButtonBack>
        <ButtonNext className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white p-2 rounded-full">
          <FaChevronRight />
        </ButtonNext>
      </CarouselProvider>
      {/* Static Price Chip */}
      <div
        className="absolute bottom-2 right-2 bg-black text-white px-4 py-2 rounded-2xl shadow-md"
        style={{ fontFamily: 'Poppins, sans-serif', fontSize: '0.875rem' }}
      >
        $120 / night
      </div>
  
    </div>
  );
};

export default ImageSlider;
