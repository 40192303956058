import React, { useRef,useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../styles/globals.css';
import Title from './2-Title';
import ImageSlider from './2-ImageSlider';
import TextAndStar from './2-TextAndStar';
import CheckInCheckOutCard from './2-CheckInCheckOutCard';
import MapCard from './2-MapCard';
import ImageGrid from './2-ImageGrid';
import PropertyInfo from './2-PropertyInfo';
import CarouselWithCards from './2-CarouselWithCards';
import FloatingFooter from './2-FloatingFooter';

gsap.registerPlugin(ScrollTrigger);

const MainPage2 = ({ updateTravelers, totalTravelers }) => {
  const checkInRef = useRef(null); // Add a ref to scroll to

  useEffect(() => {
    const elements = document.querySelectorAll('.animate');

    elements.forEach((el) => {
      gsap.fromTo(el, 
        { scale: 0.5, y: 20, opacity: 0 }, 
        {
          scale: 1,
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: el,
            start: 'top 80%',
            toggleActions: 'play none none reverse',
          },
        }
      );
    });
    
    gsap.fromTo('.text-and-star', 
      { scale: 0.5, y: 20, opacity: 0 }, 
      {
        scale: 1,
        y: 0,
        opacity: 1,
        duration: 1,
      }
    );
  }, []);

  return (
    <div className="p-4" style={{ userSelect: 'none' }}>
      <div className="animate"><Title /></div>
      <div className="animate"><ImageSlider /></div>
      <div className="text-and-star"><TextAndStar /></div>
      <CheckInCheckOutCard ref={checkInRef} updateTotalCost={updateTravelers} />
      <div className="animate"><MapCard /></div>
      <div className="animate"><PropertyInfo /></div>
      <div className="animate"><CarouselWithCards /></div>
      <FloatingFooter totalTravelers={totalTravelers} checkInRef={checkInRef} />
      </div>
  );
};

export default MainPage2;
