import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import UserReviewsMarquee from "./UserReviewsMarquee";
import {
  FaStar,
  FaHome,
  FaBath,
  FaTree,
  FaCar,
  FaFire,
  FaSnowflake,
  FaUtensils,
} from "react-icons/fa";
import { MdScreenShare } from "react-icons/md";

const CombinedCard = () => {
  const userList = [
    {
      image: "https://randomuser.me/api/portraits/men/2.jpg",
      name: "Oliver Brown",
      experience: "Stunning ocean views",
      rating: "4.5/5",
    },
    {
      image: "https://randomuser.me/api/portraits/women/3.jpg",
      name: "Sophia Green",
      experience: "Vibrant city life",
      rating: "4.7/5",
    },
    {
      image: "https://randomuser.me/api/portraits/men/4.jpg",
      name: "Liam White",
      experience: "Tranquil getaway",
      rating: "4.6/5",
    },
    {
      image: "https://randomuser.me/api/portraits/women/5.jpg",
      name: "Ava Black",
      experience: "Perfect retreat spot",
      rating: "4.8/5",
    },
    {
      image: "https://randomuser.me/api/portraits/men/6.jpg",
      name: "Noah Davis",
      experience: "Great coastal experience",
      rating: "4.5/5",
    },
    {
      image: "https://randomuser.me/api/portraits/men/7.jpg",
      name: "Mason Miller",
      experience: "Family-friendly place",
      rating: "4.7/5",
    },
    {
      image: "https://randomuser.me/api/portraits/women/8.jpg",
      name: "Isabella Wilson",
      experience: "Charming accommodation",
      rating: "4.9/5",
    },
    {
      image: "https://randomuser.me/api/portraits/men/9.jpg",
      name: "Ethan Taylor",
      experience: "Lovely scenery",
      rating: "4.6/5",
    },
    {
      image: "https://randomuser.me/api/portraits/women/10.jpg",
      name: "Mia Anderson",
      experience: "Nice neighborhood",
      rating: "4.4/5",
    },
    {
      image: "https://randomuser.me/api/portraits/men/11.jpg",
      name: "James Lee",
      experience: "Amazing experience!",
      rating: "4.8/5",
    },
  ];

  const { ref: reasonsRef, inView: reasonsInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div className="mt-6 border border-gray-300 rounded-2xl p-6 bg-white shadow-lg font-poppins">
      {/* Text and Star Section */}
      <div className="mb-4">
        <h2 className="text-lg font-bold text-gray-800 mb-2">
          Oceanfront Newport Whale Watch,Single Story Home, W/Hot Tub !
        </h2>
        <p className="text-sm text-gray-800 mb-2">
          Located in Newport, this vacation home is near theme parks and on the
          beach. Yaquina Bay Lighthouse and Yaquina Bay Bridge are local
          landmarks, and some of the area's activities can be experienced at
          Rogue Ales Microbrewery and Agate Beach Golf Course.{" "}
        </p>
        <div className="bg-black text-white p-2 rounded-lg shadow-md flex mt-1 items-center">
          <FaStar className="text-yellow-400 text-xs mr-1" />
          <span className="font-bold text-xs">5 Star</span>
        </div>
      </div>

      <h2 className="text-lg font-bold text-gray-800">User Reviews</h2>
      <div className="p-4 mb-4">
        <UserReviewsMarquee userList={userList} />
      </div>
      <hr className="my-2 mt-4 border-gray-300 mb-4" />

      {/* Icons and Text Section */}
      <h2 className="text-lg font-bold text-gray-800 mb-4">Reasons to Book</h2>
      <div ref={reasonsRef} className="grid grid-cols-3 gap-4 mb-4">
        {[
          {
            src: "https://img.icons8.com/emoji/50/shield-emoji.png",
            alt: "Safety Measures",
            text: "Safety Measures",
            delay: 0.4,
          },
          {
            src: "https://img.icons8.com/3d-fluency/94/verified-account.png",
            alt: "Includes All Essentials",
            text: "Full Featured",
            delay: 0.6,
          },
          {
            src: "https://img.icons8.com/3d-fluency/94/christmas-star.png",
            alt: "5-Star Rated",
            text: "5 Star Rated",
            delay: 0.8,
          },
        ].map((item, index) => (
          <motion.div
            key={index}
            className="flex flex-col items-center border border-gray-300 rounded-2xl p-4 bg-white shadow-md"
            initial={{ opacity: 0, y: 50 }}
            animate={
              reasonsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }
            }
            transition={{ duration: 0.6, delay: item.delay }}
          >
            <img
              src={item.src}
              alt={item.alt}
              width="30"
              height="30"
              className="mb-2"
            />
            <p className="text-gray-600 text-xs text-center">{item.text}</p>
          </motion.div>
        ))}
      </div>

      <hr className="mt-8 my-2 border-gray-300" />

      {/* Additional Details Section */}
      <div className="mb-4">
  <h2 className="text-lg font-bold text-gray-800 mb-2">Overview</h2>
  <div className="flex flex-col space-y-2">
    <div className="flex items-center text-sm text-gray-600">
      <FaHome className="mr-2 text-black" /> 2 bedrooms
    </div>
    <div className="flex items-center text-sm text-gray-600">
      <FaBath className="mr-2 text-black" /> 2 bathrooms
    </div>
    <div className="flex items-center text-sm text-gray-600">
      <FaTree className="mr-2 text-black" /> Garden
    </div>
    <div className="flex items-center text-sm text-gray-600">
      <FaCar className="mr-2 text-black" /> Garage
    </div>
  </div>
</div>

<hr className="my-2 border-gray-300" />

{/* Amenities Section */}
<div className="mb-4">
  <h2 className="text-lg font-bold text-gray-800 mb-2">Amenities</h2>
  <div className="flex flex-col space-y-2 text-sm text-gray-600">
    <div className="flex items-center">
      <MdScreenShare  className="mr-2 text-black" /> Smart TV
    </div>
    <div className="flex items-center">
      <FaFire  className="mr-2 text-black" /> Indoor fireplace
    </div>
    <div className="flex items-center">
      <FaUtensils className="mr-2 text-black" /> Fully equipped kitchen
    </div>
    <div className="flex items-center">
      <FaSnowflake className="mr-2 text-black" /> Central heating
    </div>
  </div>
</div>

      <hr className="my-2 border-gray-300" />

      {/* Safety and Hygiene Section */}
      <div className="mb-4">
        <h2 className="text-lg font-bold text-gray-800 mb-2">
          Safety and Hygiene
        </h2>
        <ul className="list-disc pl-4 text-sm text-gray-600">
          <li>Sanitized rooms</li>
          <li>Social distancing measures</li>
          <li>Contactless check-in/check-out</li>
        </ul>
      </div>

      <hr className="my-2 border-gray-300 mt-6" />

      {/* Nearby Attractions Section */}
      <div className="mb-4">
        <h2 className="text-lg font-bold text-gray-800 mb-2">
          Nearby Attractions
        </h2>
        <div className="flex flex-col space-y-2 text-sm text-gray-600">
          <ul className="list-disc pl-4">
            <li>Scenic Hiking Trails - 2 miles</li>
            <li>Local Dining Spots - 5 miles</li>
            <li>National Park - 13 miles</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CombinedCard;
