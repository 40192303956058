import React, { forwardRef, useState, useEffect } from "react";
import {
  FaUser,
  FaFileInvoice,
  FaEnvelope,
  FaUpload,
  FaCalendarAlt,
  FaPlus,
  FaMinus,
} from "react-icons/fa";
import { Calendar } from "primereact/calendar";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Lottie from "lottie-react";
import animationData from "./lottie-animation.json"; // Ensure this path is correct
import { RiCloseLine } from "react-icons/ri";
import emailjs from "emailjs-com";
import { format, parsePhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-number-input";
import "../shake.css";
import "./styles.css"; // Import the shake animation CSS
import { UploadButton } from "@bytescale/upload-widget-react";

const CheckInCheckOutCard = forwardRef((props, ref) => {
  const { updateTotalCost } = props;
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [children, setChildren] = useState(0);
  const [adults, setAdults] = useState(0);
  const [seniors, setSeniors] = useState(0);
  const [title, setTitle] = useState(
    "Ocean Front Home in the Heart of Newport "
  );
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [travelerError, setTravelerError] = useState("");
  const [shakeFields, setShakeFields] = useState({});
  const [showReceipt, setShowReceipt] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [imageUrl, setImageUrl] = useState(""); // Image URL state
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText("ClientID");
    setIsCopied(true);
  };
  const totalTravelers = Number(children) + Number(adults) + Number(seniors);
  const costPerTraveler = 120; // Cost for one traveler

  const calculateDateDifference = (startDate, endDate) => {
    if (startDate && endDate) {
      const diffTime = endDate - startDate;
      const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert to days
      return days === 0 ? 1 : days; // Ensure at least 1 day if check-in and check-out are the same
    }
    return 0;
  };

  // Function to calculate total cost based on dates and number of travelers
  const calculateTotalCost = () => {
    const dateDifference = calculateDateDifference(checkInDate, checkOutDate);
    return costPerTraveler * totalTravelers * dateDifference;
  };

  const totalCost = calculateTotalCost();

  // Update total cost on changes to relevant state
  useEffect(() => {
    if (updateTotalCost) {
      updateTotalCost(totalCost);
    }
  }, [totalCost, updateTotalCost]);

  const Counter = ({ label, count, onIncrease, onDecrease, ageRange }) => {
    return (
      <div className="mt-4 flex items-center justify-between">
        <div>
          <label className="text-s text-gray-600">{label}</label>
          <span className="block text-xs text-gray-500">{ageRange}</span>
        </div>
        <div className="flex items-center">
          <button
            onClick={onDecrease}
            className="border border-gray-300 text-gray-700 p-2 rounded-full hover:bg-gray-100"
          >
            <FaMinus />
          </button>
          <span className="mx-4 text-s font-medium">{count}</span>
          <button
            onClick={onIncrease}
            className="border border-gray-300 text-gray-700 p-2 rounded-full hover:bg-gray-100"
          >
            <FaPlus />
          </button>
        </div>
      </div>
    );
  };

  const handleChildrenChange = (action) => {
    if (action === "increase") setChildren((prev) => Math.min(prev + 1, 100));
    else setChildren((prev) => Math.max(prev - 1, 0));
  };

  const handleAdultsChange = (action) => {
    if (action === "increase") setAdults((prev) => Math.min(prev + 1, 100));
    else setAdults((prev) => Math.max(prev - 1, 0));
  };

  const handleSeniorsChange = (action) => {
    if (action === "increase") setSeniors((prev) => Math.min(prev + 1, 100));
    else setSeniors((prev) => Math.max(prev - 1, 0));
  };

  const options = {
    apiKey: "public_W142ipb5Hm5J7EpLcsuHu825yCsD",
    maxFileCount: 1,
  };

  const handleUploadComplete = (files) => {
    if (files && files.length > 0) {
      setImageUrl(files[0].fileUrl); // Assuming you want to save the file URL
      setIsFileUploaded(true); // Mark the file as uploaded
    }
  };

  const today = new Date();
  const sendEmail = (data) => {
    emailjs
      .send(
        "service_einlujp", // Replace with your EmailJS service ID
        "template_xbq9qxm", // Replace with your EmailJS template ID
        data,
        "8nLOuPigw_zfp0cvm" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("Email sent successfully", response);
        },
        (error) => {
          console.error("Error sending email", error);
        }
      );
  };

  const handleSubmit = () => {
    let errorFields = {};

    // Validate fields
    if (!fullName) errorFields.fullName = true;
    if (!email || !/\S+@\S+\.\S+/.test(email)) errorFields.email = true;

    if (totalTravelers === 0) {
      setTravelerError("Travelers cannot be zero.");
      errorFields.totalTravelers = true;
    } else {
      setTravelerError("");
    }

    if (!checkInDate) errorFields.checkInDate = true;
    if (!checkOutDate) errorFields.checkOutDate = true;
    if (!phone) errorFields.phone = true;

    if (Object.keys(errorFields).length > 0 || travelerError) {
      setErrors(errorFields);
      setShakeFields(errorFields);
      return;
    }

    setErrors({});
    setTravelerError("");
    setFormSubmitted(true);

    try {
      const phoneNumber = parsePhoneNumber(phone);
      if (!phoneNumber) {
        throw new Error("Invalid phone number.");
      }

      const formattedPhoneNumber = phoneNumber.formatInternational();

      // Prepare data for email
      const emailData = {
        title,
        fullName,
        email,
        phone: formattedPhoneNumber, // Use the formatted phone number
        children,
        adults,
        seniors,
        checkInDate: checkInDate.toLocaleDateString(),
        checkOutDate: checkOutDate.toLocaleDateString(),
        totalCost: totalCost,
        imageUrl, // Attach the uploaded image URL
      };

      sendEmail(emailData);
    } catch (error) {
      console.error("Error formatting phone number:", error);
      // Handle the error (e.g., set an error state)
    }
  };

  const closePopUp = () => {
    setFormSubmitted(false);
    setShowReceipt(false);
  };

  useEffect(() => {
    // Add or remove the blur class on body
    if (formSubmitted) {
      document.body.classList.add("body-blur");
    } else {
      document.body.classList.remove("body-blur");
    }

    const handleClickOutside = (event) => {
      const popup = document.getElementById("popup");
      if (popup && !popup.contains(event.target) && formSubmitted) {
        closePopUp();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formSubmitted, closePopUp]);

  const handleViewReceipt = () => setShowReceipt(true);

  return (
    <div ref={ref} className="check-in-check-out-card">
      <div className="relative mt-6 border border-gray-300 rounded-2xl p-6 bg-white shadow-lg font-poppins">
        <p className="text-sm font-semibold text-gray-600 mb-4">
          Let's get you booked!
        </p>

        <div className="mb-4">
          {/* Full Name Input */}
          <div className="relative mb-3">
            <FaUser className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-600" />
            <input
              type="text"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => {
                setFullName(e.target.value);
                if (e.target.value) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    fullName: false,
                  }));
                }
              }}
              className={`border-2 ${
                errors.fullName ? "border-red-500 shake" : "border-gray-400"
              } rounded-2xl w-full p-3 pl-10 text-black`}
            />
          </div>

          {/* Email Input */}
          <div className="relative mb-3">
            <FaEnvelope className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-600" />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (e.target.value) {
                  setErrors((prevErrors) => ({ ...prevErrors, email: false }));
                }
              }}
              className={`border-2 ${
                errors.email ? "border-red-500 shake" : "border-gray-400"
              } rounded-2xl w-full p-3 pl-10 text-black`}
            />
          </div>

          {/* Phone Input */}
          <div className="relative padding: 0.5rem 1rem mb-3">
            <PhoneInput
              value={phone}
              onChange={(value) => {
                setPhone(value);
                if (value) {
                  setErrors((prevErrors) => ({ ...prevErrors, phone: false }));
                }
              }}
              disableDialCodePrefill // Disable dial code prefill
              className={`PhoneInput border-2 ${
                errors.phone ? "border-red-500 shake" : "border-gray-400"
              } rounded-2xl w-full p-3 text-black`}
              placeholder="Phone Number"
              style={{ borderRadius: "1rem" }} // Rounded corners
            />
          </div>

          <div className="flex mb-4 space-x-4">
            <div className="relative flex-1">
              <FaCalendarAlt className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-600" />
              <Calendar
                value={checkInDate}
                onChange={(e) => {
                  setCheckInDate(e.value);
                  // Clear check-out date if check-in date changes to ensure valid selection
                  if (checkOutDate < e.value) {
                    setCheckOutDate(null);
                  }
                }}
                placeholder="Check-in"
                minDate={today} // Prevent past dates
                className={`border-2 ${
                  errors.checkInDate
                    ? "border-red-500 shake"
                    : "border-gray-400"
                } rounded-2xl w-full p-3 pl-10 text-black`}
              />
            </div>

            <div className="relative flex-1">
              <FaCalendarAlt className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-600" />
              <Calendar
                value={checkOutDate}
                onChange={(e) => setCheckOutDate(e.value)}
                placeholder="Check-out"
                minDate={checkInDate || today} // Prevent past dates and ensure checkout is after check-in
                className={`border-2 ${
                  errors.checkOutDate
                    ? "border-red-500 shake"
                    : "border-gray-400"
                } rounded-2xl w-full p-3 pl-10 text-black`}
              />
            </div>
          </div>

          <Counter
            label="Children"
            count={children}
            onIncrease={() => handleChildrenChange("increase")}
            onDecrease={() => handleChildrenChange("decrease")}
          />

          {/* Counter for Adults */}
          <Counter
            label="Adults"
            count={adults}
            onIncrease={() => handleAdultsChange("increase")}
            onDecrease={() => handleAdultsChange("decrease")}
          />

          {/* Counter for Seniors */}
          <Counter
            label="Seniors"
            count={seniors}
            onIncrease={() => handleSeniorsChange("increase")}
            onDecrease={() => handleSeniorsChange("decrease")}
          />

          {/* Total Travelers and Cost */}
          <div className="mt-4 text-left">
            <p className="text-sm text-gray-600 mt-1 font-semibold">
              Total Travelers: {totalTravelers}
            </p>
            <p className="text-sm text-gray-600 mt-1 font-semibold">
              Total Cost: ${totalCost}
            </p>
            <p
              className={`text-sm mt-1 font-semibold cursor-pointer ${
                isCopied ? "text-gray-600" : "text-[#0f55d8]"
              }`}
              onClick={handleCopy}
            >
              PayPal ID : ClientID (click to copy)
            </p>
            <p className="text-sm text-gray-600 mt-1">
              After the Payment attach the Payment Proof Below
            </p>
          </div>
          <div className="flex justify-center items-center mt-2">
          <UploadButton
            options={options}
            onComplete={handleUploadComplete}
          >
            {({ onClick }) => (
              <button
                onClick={onClick}
                className={`flex items-center px-6 py-3 border-2 rounded-xl transition duration-300 ${
                  isFileUploaded
                    ? 'bg-gray-200 border-gray-400 cursor-not-allowed'
                    : 'bg-white border-black hover:bg-gray-200'
                }`}
                disabled={isFileUploaded} // Disable the button if a file is uploaded
              >
                <FaUpload className="mr-2 text-xl" /> 
                {isFileUploaded ? 'File Uploaded' : 'Upload a file...'}
              </button>
            )}
          </UploadButton>
        </div>

        </div>

        {travelerError && (
          <p className="text-red-500 text-sm mb-4">{travelerError}</p>
        )}  

        <button
          onClick={handleSubmit}
          className="w-full bg-black text-white px-6 py-3 rounded-xl hover:bg-gray-800 transition text-lg"
        >
          Submit
        </button>

        {formSubmitted && !showReceipt && (
          <div className="fixed top-0 left-0 z-50 w-full h-full bg-gray-700 bg-opacity-50 flex items-center justify-center">
            <div
              id="popup"
              className="bg-white mx-4 rounded-2xl p-6 shadow-lg w-full max-w-md relative"
            >
              <RiCloseLine
                onClick={closePopUp}
                className="absolute w-8 h-8 bg-gray-200 rounded-full top-3 right-3 text-gray-800 cursor-pointer"
              />
              <Lottie
                animationData={animationData}
                loop={true}
                className="w-full h-64 mx-auto"
              />
              <p className="text-center text-l font-semibold text-black">
                Thank you for booking! We will revert to you as soon as possible
                through the provided information.
              </p>
              <div className="flex flex-col items-center p-4">
                <button
                  onClick={handleViewReceipt}
                  className="mt-4 bg-black text-white px-4 py-2 rounded-2xl hover:bg-black"
                >
                  View Receipt
                </button>
              </div>
            </div>
          </div>
        )}

        {showReceipt && (
          <div className="fixed z-50 top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-2xl p-6 shadow-lg w-full max-w-4xl mx-4 relative animate__animated animate__fadeIn animate__faster">
              <div className="flex items-center mb-6">
                <FaFileInvoice className="w-8 h-8 text-orange-500 mr-4" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  Receipt
                </h2>
              </div>
              <div className="border-t border-gray-300 pt-4">
                <p className="mb-2 text-gray-600">
                  <span className="font-semibold">Title:</span> {title}
                </p>
                <p className="mb-2 text-gray-600">
                  <span className="font-semibold">Name:</span> {fullName}
                </p>
                <p className="mb-2 text-gray-600">
                  <span className="font-semibold">Email:</span> {email}
                </p>
                <p className="mb-2 text-gray-600">
                  <span className="font-semibold">Phone:</span> {phone}
                </p>
                <p className="mb-2 text-gray-600">
                  <span className="font-semibold">Children:</span> {children}
                </p>
                <p className="mb-2 text-gray-600">
                  <span className="font-semibold">Adults:</span> {adults}
                </p>
                <p className="mb-2 text-gray-600">
                  <span className="font-semibold">Seniors:</span> {seniors}
                </p>
                <p className="mb-2 text-gray-600">
                  <span className="font-semibold">Check-in Date:</span>{" "}
                  {checkInDate?.toLocaleDateString()}
                </p>
                <p className="mb-2 text-gray-600">
                  <span className="font-semibold">Check-out Date:</span>{" "}
                  {checkOutDate?.toLocaleDateString()}
                </p>
                <p className="mb-2 text-gray-600">
                  <span className="font-semibold">Total Cost:</span> $
                  {totalCost.toFixed(2)}
                </p>
              </div>
              <div className="border-t border-gray-300 pt-4 mt-6 flex justify-center">
                <button
                  onClick={closePopUp}
                  className="bg-black text-white py-2 px-6 rounded-2xl font-semibold hover:bg-gray-800"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default CheckInCheckOutCard;
