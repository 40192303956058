import React from 'react';
import UserCard from './UserCard'; // Ensure you import UserCard correctly

const UserReviewsMarquee = ({ userList }) => {
  return (
    <div className="marquee-container">
      <div className="marquee">
        {userList.map((user, index) => (
          <div key={index} className="inline-block mx-2">
            <UserCard user={user} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserReviewsMarquee;
