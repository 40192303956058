import React from 'react';
import { motion } from 'framer-motion';

const reviews = [
  {
    name: "David Shaw",
    title: "Family coast trip",
    content: "This matriarch of the fam, spent 5 days out on the deck/big room/fire pit. Heaven for me!! All had a blast !!!",
    rating: 5,
  },
  {
    name: "Jane Doe",
    title: "Beach stay",
    content: "We had an amazing time at this beautiful property. There was something for everyone. The kids, dogs, and adults were all in awe and having the time of their lives!",
    rating: 4,
  },
  {
    name: "John Smith",
    title: "Ocean view getaway",
    content: "Really great location! Trail to beach ok for all of us, bathtubs ---liked the hot water! Would really benefit to have an actual hot tub we all felt.",
    rating: 5,
  },
  {
    name: "Emily Johnson",
    title: "We Love this House !",
    content: "Great home right on the ocean. Lots of space and well provided. We greatly recommend this home.",
    rating: 5,
  },
  {
    name: "Michael Brown",
    title: "Amazing Experience",
    content: "The location is fabulous. Wonderful ocean view. The slider from the kitchen wasn't working and is leaking. The house was not very clean, could use a good scrubbing or maybe new housekeeping service?",
    rating: 4,
  },
];

const StarRating = ({ rating }) => {
  return (
    <div className="flex mb-2">
      {[...Array(5)].map((_, i) => (
        <svg
          key={i}
          className={`w-4 h-4 ${i < rating ? 'text-yellow-400' : 'text-gray-300'}`}
          fill={i < rating ? 'currentColor' : 'none'}
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
        </svg>
      ))}
    </div>
  );
};

export default function ReviewSection() {
  return (
    <div className="mt-8 py-8 overflow-hidden">
      <h2 className="text-2xl text-center font-semibold mb-8 text-gray-800">User Reviews</h2>
      <motion.div 
        className="flex space-x-6"
        animate={{ x: [0, -1920, 0] }}
        transition={{ 
          x: { duration: 50, repeat: Infinity, ease: "linear" },
        }}
      >
        {reviews.concat(reviews).map((review, index) => (
          <div key={index} className="bg-white p-6 rounded-xl text-left shadow-md w-72 flex-shrink-0">
            <div className="bg-gray-200 p-4 rounded-lg mb-4">
              <div className="flex items-center">
                <img 
                  src={`https://randomuser.me/api/portraits/men/${index % 10}.jpg`} 
                  alt={review.name} 
                  className="w-10 h-10 rounded-full mr-3"
                />
                <div>
                  <h3 className="font-semibold text-gray-800 text-sm">{review.name}</h3>
                  <p className="text-xs text-gray-600">{review.title}</p>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200 pt-4">
              <StarRating rating={review.rating} />
              <p className="text-black-500 text-xs line-clamp-3">{review.content}</p>
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
}
