import React, { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { Typewriter, Cursor } from 'react-simple-typewriter';

const ImageGrid = () => {
  const images = [
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/5e72a3ab.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/a660755f.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/3490bba3.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/09a5389b.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/a80230e7.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/eafb3dee.jpg?impolicy=resizecrop&rw=1200&ra=fit',
  ];
  const ref = useRef(null);
  const controls = useAnimation();
  const inView = useInView(ref, { triggerOnce: false, amount: 0.2 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [inView, controls]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.8, y: 100 },
    visible: { 
      opacity: 1, 
      scale: 1, 
      y: 0,
      transition: { type: 'spring', stiffness: 100, damping: 20, duration: 0.6 },
    },
  };

  return (
    <div className="bg-white text-black py-8 mt-4 px-4">
      <h2 className="text-xl font-bold text-center mb-8">
        <Typewriter
          words={['Gallery']}
          loop={true}
          cursor
          cursorStyle='|'
          typeSpeed={70}
          deleteSpeed={50}
          delaySpeed={1000}
        />
      </h2>
      <motion.div
        className="mt-6 grid grid-cols-2 md:grid-cols-3 gap-4"
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={containerVariants}
      >
        {images.map((src, index) => (
          <motion.div
            key={index}
            className="rounded-2xl w-full h-64 overflow-hidden relative"
            variants={itemVariants}
            whileHover={{ scale: 1.1, rotate: 2, zIndex: 10 }} // Add a slight rotation and higher z-index on hover
            transition={{ type: 'spring', stiffness: 300, damping: 10 }}
          >
            <motion.img
              className="rounded-2xl w-full h-full object-cover"
              src={src}
              alt={`gallery${index + 1}`}
              initial={{ scale: 1, rotate: 0 }} // Ensure initial scale and rotation
              animate={{ scale: 1, rotate: 0 }} // Ensure image returns to original state
              transition={{ duration: 0.3 }}
            />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default ImageGrid;
