import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaBuilding } from 'react-icons/fa'; // Import the building icon

const PropertyInfo = () => {
  const images = [
    'https://media.vrbo.com/lodging/20000000/19080000/19071400/19071307/c8574f4f.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071400/19071307/b340d675.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071400/19071307/f5b99577.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071400/19071307/9db5cb08.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071400/19071307/1f7f5143.jpg?impolicy=resizecrop&rw=1200&ra=fit',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);

  return (
    <div className="mt-6 p-6 border border-gray-300 rounded-2xl bg-white shadow-lg">
      <h2 className="text-xl font-bold text-gray-800 mb-4">
        <FaBuilding className="inline w-8 h-8 mr-2 text-gray-800" />
        Property Information
      </h2>
      <p className="text-gray-700 mb-4">
        Welcome to your ideal getaway! Located in the heart of the city, this property combines convenience with luxury.
        Enjoy unparalleled comfort in our modern rooms equipped with all the latest amenities. Whether you’re here for a
        short visit or an extended stay, you’ll find everything you need for a perfect experience.
      </p>
      <hr className="my-4 border-gray-300" />
      <ul className="list-disc pl-5 mb-4 text-gray-600">
        <li>Modern and Spacious Rooms</li>
        <li>High-Speed Wi-Fi</li>
        <li>24/7 Room Service</li>
        <li>State-of-the-Art Fitness Center</li>
        <li>On-Site Dining Options</li>
      </ul>

      <motion.div
        className="mt-4 mb-4 overflow-hidden rounded-2xl relative shadow-lg"
        style={{ width: '100%', height: '300px' }} // Use full width with fixed height
      >
        <motion.img
          src={images[currentIndex]}
          alt="Property"
          className="absolute inset-0 w-full h-full object-cover rounded-2xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
        />
      </motion.div>

      <hr className="my-4 border-gray-300" />
      <p className="text-gray-700 mb-4">
        Discover the vibrant surroundings with easy access to shopping, dining, and entertainment.
        Our dedicated staff is committed to making your stay unforgettable. Book your stay with us today and
        experience the best of both worlds, luxury and convenience.
      </p>
    </div>
  );
};

export default PropertyInfo;
