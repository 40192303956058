import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link
import Navbar from './Navbar';
import CardSection from './CardSection';
import ReviewSection from './ReviewSection';
import Footer from './Footer';
import FloatingButton from './FloatingButton';

const Home = () => {
  const [blobPosition, setBlobPosition] = useState({
    top: '20%',
    left: '5%',
  });

  const [additionalBlobPosition, setAdditionalBlobPosition] = useState({
    bottom: '10%',
    right: '5%',
  });

  useEffect(() => {
    const moveBlob = () => {
      const newTop = Math.random() * 100;
      const newLeft = Math.random() * 100;
      const newBottom = Math.random() * 100;
      const newRight = Math.random() * 100;

      setBlobPosition({ top: `${newTop}%`, left: `${newLeft}%` });
      setAdditionalBlobPosition({ bottom: `${newBottom}%`, right: `${newRight}%` });
    };

    const interval = setInterval(moveBlob, 2000);
    return () => clearInterval(interval);
  }, []);

  const blobStyle = {
    position: 'absolute',
    width: '350px',
    height: '350px',
    background: 'radial-gradient(circle, rgba(255, 105, 180, 0.5), rgba(138, 43, 226, 0.5))',
    borderRadius: '50%',
    filter: 'blur(100px)',
    zIndex: -1,
    transition: 'top 2s ease, left 2s ease',
  };

  const additionalBlobStyle = {
    position: 'absolute',
    width: '400px',
    height: '400px',
    background: 'radial-gradient(circle, rgba(255, 105, 180, 0.5), rgba(138, 43, 226, 0.5))',
    borderRadius: '50%',
    filter: 'blur(90px)',
    zIndex: -1,
    transition: 'bottom 2s ease, right 2s ease',
  };

  return (
    <div className="relative flex flex-col min-h-screen overflow-hidden">
      {/* Gradient Blobs */}
      <div style={{ ...blobStyle, ...blobPosition }}></div>
      <div style={{ ...additionalBlobStyle, ...additionalBlobPosition }}></div>

      {/* Navbar and Content */}
      <Navbar />
      <div className="flex-grow flex flex-col items-center justify-center p-4 z-10">
        <CardSection /> {/* Ensure this handles links properly */}
        <ReviewSection />
      </div>

      <Footer />
      <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 20 }}>
        <FloatingButton />
      </div>
    </div>
  );
};

export default Home;
