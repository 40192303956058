import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./styles/globals.css";
import Title from "./components/Title";
import ImageSlider from "./components/ImageSlider";
import TextAndStar from "./components/TextAndStar";
import CheckInCheckOutCard from "./components/CheckInCheckOutCard";
import MapCard from "./components/MapCard";
import PropertyInfo from "./components/PropertyInfo";
import CarouselWithCards from "./components/CarouselWithCards";
import FloatingFooter from "./components/FloatingFooter";
import Home from "./components/Home";
import MainPage2 from "./components/mainpage-2"; 
import "./components/styles.css";

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  const [totalTravelers, setTotalTravelers] = useState(0);
  const checkInRef = useRef(null); // Add a ref to scroll to

  const updateTravelers = (total) => {
    setTotalTravelers(total);
  };

  useEffect(() => {
    const elements = document.querySelectorAll(".animate");

    elements.forEach((el) => {
      gsap.fromTo(
        el,
        { scale: 0.5, y: 20, opacity: 0 },
        {
          scale: 1,
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: el,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        }
      );
    });

    gsap.fromTo(
      ".text-and-star",
      { scale: 0.5, y: 20, opacity: 0 },
      {
        scale: 1,
        y: 0,
        opacity: 1,
        duration: 1,
      }
    );
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/main"
          element={
            <div className="p-4" style={{ userSelect: "none" }}>
              <div className="animate">
                <Title />
              </div>
              <div className="animate">
                <ImageSlider />
              </div>
              <div className="text-and-star">
                <TextAndStar />
              </div>
              <CheckInCheckOutCard ref={checkInRef} updateTotalCost={updateTravelers} />
              <div className="animate">
                <MapCard />
              </div>
              <div className="animate">
                <PropertyInfo />
              </div>
              <div className="animate">
                <CarouselWithCards />
              </div>
              <FloatingFooter totalTravelers={totalTravelers} checkInRef={checkInRef} />
            </div>
          }
        />
        <Route
          path="/mainpage-2"
          element={<MainPage2 updateTravelers={updateTravelers} />}
        />
      </Routes>
    </Router>
  );
};

export default App;
