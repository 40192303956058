import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaBuilding, FaWater, FaFire, FaTree, FaCar } from 'react-icons/fa'; // Import relevant icons

const PropertyInfo = () => {
  const images = [
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/5e72a3ab.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/a660755f.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/3490bba3.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/09a5389b.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/a80230e7.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/eafb3dee.jpg?impolicy=resizecrop&rw=1200&ra=fit',
    'https://media.vrbo.com/lodging/20000000/19080000/19071200/19071173/0822140d.jpg?impolicy=resizecrop&rw=1200&ra=fit',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);

  return (
    <div className="mt-6 p-6 border border-gray-300 rounded-2xl bg-white shadow-lg">
      <h2 className="text-xl font-bold text-gray-800 mb-4">
        <FaBuilding className="inline w-8 h-8 mr-2 text-gray-800" />
        Property Information
      </h2>
      <p className="text-gray-700 mb-4">
        This sizable cozy home gazes at the Pacific Ocean and is just a stone throw away from the local sandy beach. The hypnotic sounds of the ocean waves crashing, the salty beach air, and a curious sea otter poking its head out of the water—all observed from this beautiful four-bedroom, two-bath home.
      </p>
      <p className="text-gray-700 mb-4">
        Nestled in Newport, this home is just minutes away from the Historical Nye Beach, bay front shopping, and adored restaurants—all waiting for you to experience.
      </p>
      <hr className="my-4 border-gray-300" />
      <ul className="list-disc pl-5 mb-4 text-gray-600">
        <li><FaTree className="inline mr-2" /> Modern Spacious Rooms</li>
        <li><FaWater className="inline mr-2" /> High-Speed Wi-Fi</li>
        <li><FaFire className="inline mr-2" /> Cozy Fireplace</li>
        <li><FaCar className="inline mr-2" /> On-Site Parking</li>
      </ul>

      <motion.div
        className="mt-4 mb-4 overflow-hidden rounded-2xl relative shadow-lg"
        style={{ width: '100%', height: '300px' }} // Use full width with fixed height
      >
        <motion.img
          src={images[currentIndex]}
          alt="Property"
          className="absolute inset-0 w-full h-full object-cover rounded-2xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
        />
      </motion.div>

      <hr className="my-4 border-gray-300" />
      <p className="text-gray-700 mb-4">
        Discover the vibrant surroundings with easy access to shopping, dining, and entertainment. Our dedicated staff is committed to making your stay unforgettable. Book your stay with us today and experience the best of both worlds, luxury and convenience.
      </p>
    </div>
  );
};

export default PropertyInfo;
