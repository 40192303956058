import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showProperties, setShowProperties] = useState(false);
  const navigate = useNavigate();

  return (
    <nav
      className={`fixed top-4 left-1/2 transform -translate-x-1/2 z-50 backdrop-blur-lg border border-white/20 p-4 rounded-2xl shadow-md transition-all duration-300 ${isOpen ? 'bg-white/60' : 'bg-white/30'} mx-auto`}
      style={{ width: '92%', maxWidth: '400px' }}
    >
      <div className="flex justify-between items-center">
        {/* Home Button with Icon */}
        <div
          className="flex items-center space-x-2 cursor-pointer text-black" 
          onClick={() => navigate('/')}
        >
          <motion.img
            src="https://img.icons8.com/3d-fluency/94/booking.png"
            alt="booking"
            width="24"
            height="24"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="transition-all duration-200"
          />
          <span className="text-lg font-semibold">Home</span>
        </div>

        {/* Animated Hamburger Menu */}
        <motion.button
          className="focus:outline-none text-xl text-black"
          onClick={() => setIsOpen(!isOpen)}
          animate={{ rotate: isOpen ? 45 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <motion.img
            src={isOpen ? "https://img.icons8.com/3d-fluency/94/delete-sign.png" : "https://img.icons8.com/3d-fluency/94/menu.png"}
            alt={isOpen ? "close" : "menu"}
            width="20"
            height="20"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="transition-all duration-200"
          />
        </motion.button>
      </div>

      {/* Dropdown Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden mt-4"
          >
            <hr className="my-2 border-gray-300" />

            {/* Toggle for Properties */}
            <div
              onClick={() => setShowProperties(!showProperties)}
              className="flex items-center text-black cursor-pointer hover:underline hover:text-blue-500 transition duration-200 mt-2"
            >
              <motion.img
                src="https://img.icons8.com/3d-fluency/94/company.png"
                alt="company"
                width="24"
                height="24"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="transition-all duration-200"
              />
              <span className="text-lg ml-2 font-medium">Properties</span>
            </div>

            {/* Properties Dropdown */}
            <AnimatePresence>
              {showProperties && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="ml-6 mt-2"
                >
                  <div 
                    onClick={() => navigate('/main')}
                    className="cursor-pointer ml-2 text-sm text-gray-800 hover:underline hover:text-blue-500 mb-2"
                  >
                    Ocean Front Home in the Heart of Newport 
                  </div>
                  <hr className="my-2 border-gray-100" />
                  <div 
                    onClick={() => navigate('/mainpage-2')}
                    className="cursor-pointer ml-2 text-sm text-gray-800 hover:underline hover:text-blue-500"
                  >
                    Oceanfront Newport Whale Watch, Single Story Home, W/Hot Tub !
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <hr className="my-2 border-gray-300" />

            {/* Contact Us */}
            <div
              onClick={() => window.location.href = 'mailto:anvteamofficial@gmail.com'}
              className="flex items-center text-black cursor-pointer hover:underline hover:text-blue-500 transition duration-200 mt-2"
            >
              <motion.img
                src="https://img.icons8.com/3d-fluency/94/administrator-male--v2.png"
                alt="administrator"
                width="24"
                height="24"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="transition-all duration-200"
              />
              <span className="text-lg ml-2 font-medium">Contact Us</span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
