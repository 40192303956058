import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FaShareAlt, FaFacebookF, FaTwitter, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard'; // Ensure this package is installed

const Popup = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: ${(props) => (props.showSharePopup ? 'translateY(0)' : 'translateY(100%)')};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  border-top: 1px solid #ddd;
  border-radius: 12px 12px 0 0;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

const Card = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
`;

const FloatingFooter = ({ totalTravelers, checkInRef }) => {
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('');
  const [copyFeedback, setCopyFeedback] = useState('');
  const popupRef = useRef(null);

  useEffect(() => {
    setCurrentUrl(window.location.href); // Get the current URL

    // Close the popup when clicking outside of it
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowSharePopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const togglePopup = () => setShowSharePopup(!showSharePopup);

  const handleCopy = () => {
    setCopyFeedback('Copied!');
    setTimeout(() => setCopyFeedback(''), 2000); // Clear feedback after 2 seconds
  };

  const scrollToCheckIn = () => {
    if (checkInRef.current) {
      checkInRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('checkInRef is undefined');
    }
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-300 shadow-lg rounded-t-2xl">
      <div className="flex justify-between items-center p-4">
        {/* Share Button */}
        <button 
          onClick={togglePopup}
          className="bg-black text-white px-4 py-2 rounded-full flex items-center"
        >
          <FaShareAlt className="mr-2" />
          Share
        </button>

        {/* Check Availability Button */}
        <button 
          onClick={scrollToCheckIn} 
          className="bg-black text-white px-4 py-2 rounded-full"
        >
          Check Availability
        </button>
      </div>

      {/* Share Popup */}
      <Popup ref={popupRef} showSharePopup={showSharePopup}>
        <div className="flex flex-col items-start">
          <div className="flex items-center mb-4 w-full">
            <input
              type="text"
              value={currentUrl}
              readOnly
              className="border border-gray-300 rounded-l-2xl p-2 w-full"
            />
            <CopyToClipboard text={currentUrl} onCopy={handleCopy}>
              <button className="bg-gray-200 text-gray-800 p-2 rounded-r-2xl">
                Copy
              </button>
            </CopyToClipboard>
          </div>
          {copyFeedback && <div className="mb-4 text-green-500">{copyFeedback}</div>}
          
          <div className="flex items-center justify-between">
            <Card>
              <FacebookShareButton
                url={currentUrl}
                quote={"Check this out!"}
                hashtag={"#example"}
              >
                <FaFacebookF size={26} className="text-blue-600" />
              </FacebookShareButton>
            </Card>
            <Card>
              <TwitterShareButton
                title={"Check this out!"}
                url={currentUrl}
                hashtags={["example"]}
              >
                <FaTwitter size={26} className="text-blue-400" />
              </TwitterShareButton>
            </Card>
            <Card>
              <WhatsappShareButton url={currentUrl} title={"Check this out!"}>
                <FaWhatsapp size={26} className="text-green-500" />
              </WhatsappShareButton>
            </Card>
            <Card>
              <LinkedinShareButton url={currentUrl}>
                <FaLinkedinIn size={26} className="text-blue-700" />
              </LinkedinShareButton>
            </Card>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default FloatingFooter;
